!function (window, document) {
    'use strict';

    const Cookies = require('js-cookie');

    class Embeds {
        possibleProviders = Object.freeze(['twitter', 'instagram', 'facebook', 'youtube', 'tiktok', 'kwiss', 'spotify', 'glomex', 'reddit', 'opinary', 'pinterest'] )
        _consentLifetimeMonth = 12;
        _cookieName = 'sso_user_allowed_embeds';
        /** @type Set<string> */
        _allowedProviders;
        /** @type Array.<{embedProvider: string, callback: Function}> */
        _callbacks = [];
        _domain = location.host.substring(0, 4) === 'www.' ? location.host.substring(4) : location.host;

        constructor() {
            this._allowedProviders = this._readCookie();
        }

        /**
         * Consent wrapper for the embed code. If/once the provider is allowed
         * it will load the embed. Otherwise it will wait for the provider
         * to become available.
         * @param embedProvider {string}
         * @param embedCode {string}
         * @param containerId {string}
         * @param classToRemove {string}
         */
        embedDependsOn(embedProvider, embedCode, containerId, classToRemove) {
            console.log('%cTrace: ', 'background: #ffb300; color: white;', 'embed depends on', embedProvider, containerId);
            const callback = () => {
                console.log('%cTrace: ', 'background: #ffb300; color: white;', 'loading embed code', embedProvider, containerId);
                const container = document.getElementById(containerId);
                container.innerHTML = embedCode;
                container.classList.remove('embed-blocked');
                this._fixScriptsInEmbedContainer(container);
                Embeds._showRevokeLink(container);
            };
            this._doWhenAllowed(embedProvider, callback);
        }

        /**
         * Consent wrapper for the embed script. If/once the provider is allowed
         * it will load the embed script. Otherwise it will wait for the provider
         * to become available.
         * @param embedProvider {string}
         * @param scriptSrc {string}
         * @param containerId {string}
         */
        scriptDependsOn(embedProvider, scriptSrc, containerId) {
            console.log('%cTrace: ', 'background: #ffb300; color: white;', 'script depends on', embedProvider, containerId);
            const callback = () => {
                console.log('%cTrace: ', 'background: #ffb300; color: white;', 'loading embed script', embedProvider, containerId);
                console.log(`Loading script for embed ${embedProvider}`);
                try {
                    const script = this._buildScriptNode(scriptSrc);
                    document.getElementById(containerId).appendChild(script);
                } catch (e) {
                    console.log('%cTrace: ', 'background: #ffb300; color: white;', 'loading embed script failed', embedProvider, containerId);
                }
            };

            this._doWhenAllowed(embedProvider, callback);
        }

        /**
         * Consent wrapper for an embed related callback. Facebook for example needs code not just a script.
         * @param embedProvider {string}
         * @param callback {Function}
         */
        callbackDependsOn(embedProvider, callback) {
            console.log('%cTrace: ', 'background: #ffb300; color: white;', 'callback depends on', embedProvider);
            this._doWhenAllowed(embedProvider, callback);
        }

        /**
         * Grant/revoke embed provider consent based on a user interaction (decision)
         * @param embedProvider {string}
         * @param allowed {boolean}
         */
        userDecision(embedProvider, allowed) {
            console.log('%cTrace: ', 'background: #ffb300; color: white;', 'user decision', embedProvider, allowed);
            if (!this.possibleProviders.includes(embedProvider)) return;

            if (allowed) {
                this._allowedProviders.add(embedProvider);
            } else {
                this._allowedProviders.delete(embedProvider);
            }
            this._writeCookie(this._allowedProviders);
            this._checkAllCallbacks();
            if (!allowed) {
                location.reload();
            }
        }

        /**
         * Legacy function emulating old behavior during transition.
         * @deprecated
         * @param embedProvider {string}
         * @param containerId {string}
         */
        userAllowed(embedProvider, containerId) {
            this.userDecision(embedProvider, true);
        }

        /**
         * If allowed calls callback. Otherwise stores it for when it may get allowed.
         * @param embedProvider {string}
         * @param callback {Function}
         */
        _doWhenAllowed(embedProvider, callback) {
            console.log('%cTrace: ', 'background: #ffb300; color: white;', 'asking if allowed', embedProvider);
            if (!this.possibleProviders.includes(embedProvider)) return;

            if (embedProvider === 'opinary') {
                setTimeout(() => sso.consent.dependsOn(sso.consent.vendorList.Opinary, callback));
                return;
            }

            if (this._allowedProviders.has(embedProvider)) {
                setTimeout(() => callback());
            } else {
                this._callbacks.push({embedProvider, callback});
            }
        }

        /**
         * Check if any of the waiting requests can now be executed because the embed
         * provider became available.
         */
        _checkAllCallbacks() {
            const l1 = this._callbacks.map((i) => i.embedProvider).join(',');
            console.log('%cTrace: ', 'background: #ffb300; color: white;', 'check all callbacks', l1);

            const callbacks = this._callbacks.splice(0);
            callbacks.forEach(({embedProvider, callback}) => this._doWhenAllowed(embedProvider, callback));

            const l2 = this._callbacks.map((i) => i.embedProvider).join(',');
            console.log('%cTrace: ', 'background: #ffb300; color: white;', 'check all callbacks (AFTER)', l2);
        }

        /**
         * Script tags added via innerHTML are not executed.
         * This script copies them and adds them via JS to execute.
         * @param container {HTMLElement}
         */
        _fixScriptsInEmbedContainer(container) {
            const scripts = Array.from(container.getElementsByTagName('script'));
            scripts.forEach((script) => {
                const copy = document.createElement('script');
                const attributes = script.attributes;
                for (let i = 0; i < attributes.length; i++) {
                    const attribute = attributes.item(i);
                    copy.setAttribute(attribute.name, attribute.value);
                }
                script.parentElement.removeChild(script);
                container.appendChild(copy);
            });
        }

        /**
         * Recreates the script element string as DOM insertable node
         * @param encodedScriptSrc {string}
         * @return {HTMLScriptElement}
         * @private
         */
        _buildScriptNode(encodedScriptSrc) {
            const parser = new DOMParser()
            const scriptSrc = parser.parseFromString(encodedScriptSrc, 'text/html').documentElement.textContent;
            const script = document.createElement('script');
            const tmp = document.createElement('div');
            tmp.innerHTML = scriptSrc;
            [...tmp.firstChild.attributes].forEach((attr) => script.setAttribute(attr.nodeName, attr.nodeValue));
            return script;
        }

        /**
         * Display the previously hidden revoke link for this embed container
         * @param container {HTMLElement}
         */
        static _showRevokeLink(container) {
            container
                .closest('.article-media')
                .querySelectorAll('footer .article-media-privacy .revoke')
                .forEach((elem) => elem.classList.remove('d-none'));
        }

        /**
         * Reads embed cookie and returns allowed embed providers
         */
        _readCookie() {
            const cookieValue = Cookies.get(this._cookieName) ??  '';
            const values = cookieValue.split(',').filter((i) => this.possibleProviders.includes(i));
            return new Set(values);
        }

        /**
         * Updates/sets the embed cookie based on currently allowed list of embed providers
         * @param values {Set<string>}
         */
        _writeCookie(values) {
            const cookieValue = Array.from(values).sort().join(',');
            const expires = new Date();
            expires.setMonth(expires.getMonth() + this._consentLifetimeMonth);
            Cookies.set(
                this._cookieName,
                cookieValue,
                {expires, path: '/', domain: this._domain, secure: true, sameSite: 'strict'}
            )
            console.log('%cTrace: ', 'background: #ffb300; color: white;', 'writing cookie');
        }

        deleteCookie() {
            Cookies.remove(this._cookieName, {domain: this._domain} );
        }
    }

    window.sso.embeds = new Embeds();
}(window, document);
